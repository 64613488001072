import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios';
import './WeatherApp.css'
import search_icon from '../Assets/search.png'
import clear_icon from '../Assets/clear.png'
import cloud_icon from '../Assets/cloud.png'
import drizzle_icon from '../Assets/drizzle.png'
import rain_icon from '../Assets/rain.png'
import snow_icon from '../Assets/snow.png'
import wind_icon from '../Assets/wind.png'
import humidity_icon from '../Assets/humidity.png'

const WeatherApp = () => {

    let api_key = "56ea9b6a1bf7425723c8b0f1f2a94417";
    const [wicon, setWicon] = useState(cloud_icon);
    const inputRef = useRef();

    const search = async () => {
        const element = document.getElementsByClassName("cityInput");
        if (element[0].value === ""){
            return 0;
        }
        let url = `https://api.openweathermap.org/data/2.5/weather?q=${element[0].value}&units=Metric&appid=${api_key}`;

        axios.get(url)
        .then(response => {
            let data = response.data;

            const humidity = document.getElementsByClassName("humidity-percent");
            const wind = document.getElementsByClassName("wind-rate");
            const temperature = document.getElementsByClassName("weather-temp");
            const location = document.getElementsByClassName("weather-location");

            humidity[0].innerHTML = data.main.humidity + " %";
            wind[0].innerHTML = Math.floor(data.wind.speed) + " km/h";
            temperature[0].innerHTML = Math.floor(data.main.temp) + "°C";
            location[0].innerHTML = data.name;
            const weatherIcon = data.weather[0].icon;
            if (["01d", "01n"].includes(weatherIcon)) {
                return setWicon(clear_icon);
            }
            if (["02d", "02n"].includes(weatherIcon)) {
                return setWicon(cloud_icon);
            }
            if (["03d", "03n", "04d", "04n"].includes(weatherIcon)) {
                return setWicon(drizzle_icon);
            }
            if (["09d", "09n", "10d", "10n"].includes(weatherIcon)) {
                return setWicon(rain_icon);
            }
            if (["13d", "13n"].includes(weatherIcon)) {
                return setWicon(snow_icon);
            }
            return setWicon(clear_icon);
        })
        .catch(error => {
            console.error('There was an error making the request:', error);
        })
    }

    useEffect(()=> {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                search();
            }
        };

        const inputElement = inputRef.current;
        inputElement.addEventListener('keydown', handleKeyDown)

        return () => {
            inputElement.removeEventListener('keydown', handleKeyDown);
        };
    })
  return (
    <div className="container">
        <div className="top-bar">
            <input type="text" class="cityInput" placeholder="Search" ref={inputRef} />
            <div className="search-icon" onClick={() => {search()}}>
                <img src={search_icon} alt="" />
            </div>
        </div>
        <div className="weather-image">
            <img src={wicon} alt="" />
        </div>
        <div className="weather-temp">24°c</div>
        <div className="weather-location">London</div>
        <div className="data-container">
            <div className="element">
                <img src={humidity_icon} alt="" className="icon" />
                <div className="data">
                    <div className="humidity-percent">64%</div>
                    <div className="text">Humidity</div>
                </div>
            </div>
            <div className="element">
                <img src={wind_icon} alt="" className="icon" />
                <div className="data">
                    <div className="wind-rate">18 km/h</div>
                    <div className="text">Wind Speed</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WeatherApp